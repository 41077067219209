import React, { useState } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, Link, graphql } from "gatsby";
import FreeVideo from "./video/freeVideo";

const Carousel = (props) => {
  // https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/navigation-controls/arrows-and-dots/react?file=/src/App.js:348-470
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      renderMode: "performance",
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      // https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/autoplay/autoswitch/react?file=/src/App.js:243-1071
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 4000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  const data = useStaticQuery(graphql`
    query {
      videoJson(slug: { eq: "nna-87" }) {
        id
        category
        created
        description
        no
        slug
        series
        title
      }
    }
  `);

  return (
    <div className="relative">
      <div ref={sliderRef} className="keen-slider">
        <div className="keen-slider__slide relative">
          <StaticImage
            src="../images/carousel-landmark.webp"
            alt="ランドマークタワー"
            layout="fullWidth"
          />
          <div className="absolute top-1/2 -translate-y-1/2 xl:top-20 xl:translate-y-0 right-0 xl:right-20 flex justify-end w-full bg-cyan-400 bg-opacity-40 xl:bg-inherit p-3 md:p-3 xl:p-0">
            <div className="w-full xl:w-2/5 text-white">
              <div className="font-ebgaramond text-center text-xl md:text-2xl xl:text-3xl tracking-normal xl:tracking-wider italic font-light">
                Go straight forward
              </div>
              <div className="text-sm md:text-lg leading-normal xl:leading- loose text-center tracking-normal xl:tracking-wider mt-2 xl:mt-5">
                <span className="text-lg md:text-2xl">
                  水野コンサルタンシーホールディングス
                </span>
                は
                <br />
                中国ビジネスコンサルタント・水野真澄が代表を務める
                <br />
                コンサルティングファームです。
              </div>
              <div className="hidden md:block md:text-sm lg:text-base md:leading-loose font-extralight md:mt-5 md:text-center md:tracking-wide">
                中国・ベトナムでビジネスを展開されている日本企業の皆様へ
                <br />
                組織再編、外貨決済、通関手続・コンプライアンス、保税品・設備関連、記帳・税務申告、国際課税、買収・撤退などをはじめ
                <br />
                高度化・複雑化する諸問題に対し安心のトータルソリューションを提供いたします。
              </div>
              <div className="text-center">
                <Link
                  className="mt-5 md:mt-10 px-5 md:px-10 py-2 md:py-3 bg-cyan-800 hover:bg-cyan-700 font-sans inline-block text-sm md:text-base"
                  to="/aboutus/about"
                >
                  詳しく見る
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`keen-slider__slide relative${loaded ? "" : " hidden"}`}
        >
          <StaticImage
            src="../images/carousel-consulting.webp"
            alt="コンサルティング"
            layout="fullWidth"
          />
          <div className="absolute top-1/2 -translate-y-1/2 xl:top-20 xl:translate-y-0 right-0 xl:right-20 flex justify-end w-full bg-white bg-opacity-60 xl:bg-inherit p-3 md:p-3 xl:p-0">
            <div className="w-full xl:w-2/5 text-gray-800">
              <div className="font-ebgaramond text-center text-xl md:text-2xl xl:text-3xl tracking-normal xl:tracking-wider italic font-light">
                Consulting Service
              </div>
              <div className="text-xl md:text-2xl xl:text-3xl leading-normal xl:leading-loose text-center tracking-normal xl:tracking-wider mt-2 md:mt-5">
                コンサルティングサービス
              </div>
              <div className="text-xs md:text-base leading-normal xl:leading-loose font-extralight mt-2 md:mt-5 text-center tracking-wide md:tracking-wide">
                回数無制限のご相談対応
                <br />
                月1時間の無料面談
                <br />
                会員様向け情報発信
                <br />
                会員様向け中国ビジネス講習会の開催
              </div>
              <div className="text-center">
                <Link
                  className="mt-2 md:mt-10 px-5 md:px-10 py-2 md:py-3 bg-cyan-200 hover:bg-cyan-300 font-sans inline-block text-sm md:text-base"
                  to="/service"
                >
                  詳しく見る
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`keen-slider__slide relative${loaded ? "" : " hidden"}`}
        >
          <StaticImage
            src="../images/carousel-seminar2.webp"
            alt="セミナー"
            layout="fullWidth"
          />
          <div className="absolute top-1/2 -translate-y-1/2 xl:top-20 xl:-translate-y-5 left-0 xl:left-20 -translate-x-0 xl:-translate-x-5 flex justify-start w-full bg-gray-800 bg-opacity-40 xl:bg-inherit p-3 md:p-5 xl:p-0">
            <div className="w-full xl:w-2/5 flex items-center xl:block">
              <div className="text-white bg-inherit xl:bg-gray-800 xl:bg-opacity-40 p-0 md:p-5 w-1/2 xl:w-full">
                <div className="font-ebgaramond text-center text-xl md:text-2xl xl:text-3xl tracking-normal xl:tracking-wider italic font-light">
                  Online Seminar
                </div>
                <div className="text-lg md:text-2xl xl:text-3xl leading-normal xl:leading-loose text-center tracking-normal xl:tracking-wider mt-2 xl:mt-5">
                  中国ビジネス解説動画
                </div>
                <div className="hidden md:block md:text-sm xl:text-base md:leading-normal xl:leading-loose font-extralight md:mt-5 md:text-center md:tracking-wide">
                  本コンテンツは、弊社グループ代表水野真澄が共同通信グループNNAで連載中の「中国ビジネス講座」の2021年1月以降の全記事を分かりやすく解説。
                  <br />
                  更には、中国の制度（外貨管理、税関、税務、会社管理制度など）にスポットをあてたシリーズや最新ホットトピックなど、
                  中国ビジネスに役立つ内容を各回15分前後で解説する動画サービスです。
                </div>
                <div className="text-center">
                  <Link
                    className="mt-5 md:mt-10 px-5 md:px-10 py-2 md:py-3 bg-cyan-800 hover:bg-cyan-700 font-sans inline-block text-sm md:text-base"
                    to="/videos"
                  >
                    詳しく見る
                  </Link>
                </div>
              </div>
              <FreeVideo
                videoJson={data.videoJson}
                withDoc={false}
                className="w-1/2 xl:w-full"
              />
            </div>
          </div>
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div
          id="dots"
          className="absolute bottom-2 xl:bottom-5 w-full flex justify-center gap-5"
        >
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={`w-3 h-3 rounded-full ${
                  currentSlide === idx ? "bg-cyan-300" : "bg-gray-100"
                }`}
                aria-label="dot"
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
