import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "news" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        nodes {
          frontmatter {
            title
            slug
            date
            category
          }
        }
      }
    }
  `);
  const nodes = data.allMarkdownRemark.nodes;
  const length = nodes.length;

  return (
    <div id="news" className="mb-5 md:mb-10">
      <h3 className="mb-3 md:mb-5 tracking-widest">
        <span className="font-ebgaramond text-lg md:text-2xl text-cyan-700 italic mr-1 md:mr-3">
          NEWS
        </span>
        <span className="text-xs md:text-sm text-gray-500">ニュース</span>
      </h3>
      <div className="border-y">
        {nodes.map((node, index) => (
          <Link
            to={node.frontmatter.slug}
            key={index}
            className={`block${index + 1 < length ? " border-b" : ""}`}
          >
            <div className="p-3 md:p-5 hover:bg-cyan-100 text-sm md:text-base flex">
              <div className="font-ebgaramond w-1/4 md:w-1/6">
                {node.frontmatter.date}
              </div>
              <div className="w-3/4 md:w-5/6">{node.frontmatter.title}</div>
            </div>
          </Link>
        ))}
      </div>
      <div className="flex justify-end">
        <Link
          to="/news"
          className="text-xs md:text-sm font-sans mt-3 md:mt-5 hover:underline text-gray-400"
        >
          ニュース一覧
        </Link>
      </div>
    </div>
  );
};

export default News;
