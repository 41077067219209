import React from "react";
import Layout from "../components/layout";
import Search from "../components/search";
import RecentPosts from "../components/recentPosts";
import Carousel from "../components/carousel";
import News from "../components/news";
import Seo from "../components/seo";

const indices = [{ name: `Pages`, title: `Pages` }];

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="水野コンサルタンシーホールディングス" />
      <main>
        <Carousel />
        <div className="bg-cyan-100 py-10">
          <Search indices={indices} />
        </div>
        <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20">
          <News />
          <RecentPosts />
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
