import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RecentVideos = () => {
  const data = useStaticQuery(graphql`
    query {
      allVideoJson(
        sort: { fields: [created, series, no], order: [DESC, DESC, DESC] }
        limit: 6
      ) {
        nodes {
          category
          created
          description
          title
          series
          slug
          no
          type
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allVideoJson.nodes.map((node, index) => (
        <Link
          to={`/videos/${node.slug}`}
          key={index}
          className="hover:bg-cyan-100 border border-cyan-400 block relative font-sans"
        >
          <div className="absolute -top-[1px] -left-[1px] text-xs z-10">
            {node.type === "free" && (
              <div className="bg-teal-500 text-white px-2 py-1">無料</div>
            )}
            {node.type === "member" && (
              <div className="bg-cyan-500 text-white px-2 py-1">会員</div>
            )}
            {node.type === "option" && (
              <div className="bg-cyan-800 text-white px-2 py-1">
                オプション会員
              </div>
            )}
          </div>
          <GatsbyImage image={getImage(node.image)} alt={node.slug} />
          <div key={index} className="p-5">
            <div className="mb-3">{node.title}</div>
            <div className="text-sm mb-2">
              {node.created}
              {node.category ? " | " + node.category : null}
            </div>
            <div className="text-sm">{node.description}</div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default RecentVideos;
