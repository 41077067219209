import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { memberCategories, freeCategories } from "./category";
import RecentVideos from "./recentVideos";

const RecentPosts = () => {
  const cats = [...memberCategories, ...freeCategories];
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { in: ["free", "member"] } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 18
      ) {
        nodes {
          frontmatter {
            title
            slug
            date
            category
          }
          fields {
            collection
          }
          excerpt(truncate: true, pruneLength: 100)
        }
      }
    }
  `);
  return (
    <div id="recentPosts">
      <h3 className="mb-3 md:mb-5 tracking-widest">
        <span className="font-ebgaramond text-lg md:text-2xl text-cyan-700 italic mr-1 md:mr-3">
          CONTENTS
        </span>
        <span className="text-xs md:text-sm text-gray-500">コンテンツ</span>
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
        <RecentVideos />
        {data.allMarkdownRemark.nodes.map((node, index) => (
          <div
            key={index}
            className="font-sans px-5 pt-8 pb-5 border border-cyan-400 relative hover:bg-cyan-100"
          >
            <div className="absolute -top-[1px] -left-[1px] text-xs">
              {node.fields.collection === "member" ? (
                <div className="bg-cyan-500 text-white px-2 py-1">会員</div>
              ) : (
                <div className="bg-teal-500 text-white px-2 py-1">無料</div>
              )}
            </div>
            <div className="mb-3">
              <Link
                to={`${node.frontmatter.slug}`}
                className="hover:text-blue-500"
              >
                {node.frontmatter.title}
              </Link>
            </div>
            <div className="text-sm mb-3">{node.excerpt}</div>
            <div className="text-sm text-gray-600">
              <div className="mr-3 font-number font-light">
                {node.frontmatter.date}
              </div>
              <div>
                <Link
                  to={`${node.frontmatter.category}`}
                  className="hover:text-blue-500 font-light"
                >
                  {
                    cats.find((c) => c.catSlug === node.frontmatter.category)
                      .catName
                  }
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentPosts;
